<template>
  <div class="" id="registration">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row">
        <div v-if="showRules || showRulesStatus" class="col-md-8 offset-md-2 p-5 bg-white mt-3 rounded-3">
          <div class="row">
            <div class="col-12" v-html="rules"></div>
          </div>
          <div class="row">
            <div class="col-12 text-end"><button @click="hideRules" type="button" class="btn btn-danger"><i class="fa fa-times"></i> Close</button></div>
          </div>
        </div>
        <div class="col-md-8 offset-md-2 p-5 bg-white mt-3 mb-3 rounded-3">
          <h3 class="text-start text-dark fw-bolder">
            <img :src="eventLogo" alt="Event logo" class="small-logo">
            {{ eventCode }} online registration form <br/> (Selection round and Regional round)
          </h3>

          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="studentInfo.name" class="form-control" id="name" placeholder="Mr" required>
                    <label for="name">Participant name<span class="text-danger">*</span></label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="studentInfo.school" class="form-control" id="school" placeholder="xyz">
                    <label for="school">School name<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" id="divisionId" aria-label="Floating label select example" v-model="studentInfo.divisionId" @change="getDistrict">
                      <option :value="null">--Select--</option>
                      <option :value="division.id" v-for="(division,key) in divisions" :key="key">{{ division.name }}</option>
                    </select>
                    <label for="divisionId">Select Division<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" id="districtId" aria-label="Floating label select example" v-model="studentInfo.districtId">
                      <option :value="null">--Select--</option>
                      <option :value="district.id" v-for="(district,key) in districts" :key="key">{{ district.name }}</option>
                    </select>
                    <label for="districtId">Select District<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <select class="form-select" id="classId" aria-label="Floating label select example" v-model="studentInfo.classId">
                      <option :value="null">--Select--</option>
                      <option :value="className.id" v-for="(className,key) in classes" :key="key">{{ className.name }}</option>
                    </select>
                    <label for="classId">Select Class<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" name="email" v-model="studentInfo.email" class="form-control" id="email" placeholder="example@example.com" required>
                    <label for="mobile">Email address<span class="text-danger">*</span></label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-floating mb-3">
                    <input type="text" name="mobile" v-model="studentInfo.mobile" class="form-control" id="mobile" maxlength="11" minlength="11" placeholder="01XXXXXXXXX" required>
                    <label for="mobile">Mobile<span class="text-danger">*</span>(Don't use Teletalk no.)</label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-floating mb-3">
                    <select class="form-select" v-model="studentInfo.gender" id="gender" aria-label="Floating label select example">
                      <option :value="null">--Select--</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <label for="gender">Gender<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-floating mb-3">
                    <input type="text" v-model="studentInfo.postCode" class="form-control" id="postCode" placeholder="Mr">
                    <label for="postCode">Post code</label>
                    <div class="valid-feedback">
                      This field must be filled!!!
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-floating mb-3">
                    <input class="form-control text-uppercase" v-model="studentInfo.transactionId" placeholder="Leave a comment here" id="floatingTransactionID"/>
                    <label for="floatingTransactionID">bKash Transaction ID(Capital Letters)<span class="text-danger">*</span></label>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-floating mb-3">
                    <textarea class="form-control" v-model="studentInfo.address" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                    <label for="floatingTextarea2">Detail address <span class="text-danger">*</span></label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4 mb-3">
              <div class="row">
                <div class="col-12">
                  <image-upload :parentData="{avatarUrl:avatarUploadUrl, participantId: id}"/>
                </div>
              </div>
            </div>

            <div class="col-md-8 text-start">
              <div class="row">
                <div class="col-md-6 mb-md-0 mb-sm-3">
                  <button type="submit" @click="formSubmit" class="btn btn-success form-control" id="submit"><i class="fa fa-save"></i> Complete Registration</button>
                </div>
                <div class="col-md-6">
                  <button type="reset" @click="formReset" class="btn btn-danger form-control" id="reset"><i class="fa fa-times-circle"></i> Cancel Registration</button>
                </div>
                <div class="col-12">
                  <h3 class="text-start text-danger" id="errorMessage"></h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ImageUpload from "../components/ImageUpload";
  import Preloader from "../components/Preloader";
  export default {
    name: "Registration",
    data(){
      return {
        showRules: false,
        showRulesStatus: null,
        showModal: false,
        pageStatus:false,
        eventCode:sessionStorage.getItem('eventCode'),
        eventLogo:sessionStorage.getItem('eventLogo'),
        avatarUploadUrl:this.$http.options.root+'avatar/upload',
        paymentUrl:this.$http.options.root+'bkash/pay',
        id:null,
        divisions:[],
        districts:[],
        categories:[],
        classes:[],
        studentInfo:{
          name:null,
          school:null,
          divisionId:null,
          districtId:null,
          classId:null,
          mobile:null,
          email:null,
          gender:null,
          postCode:null,
          address:null,
          transactionId:null,
          ppUrl:''
        },
        rules:null,
        loaded:false,
        registrationStat:null
      }
    },
    beforeCreate(){

    },
    created() {
      this.$http.get("get-divisions").then(function (response) {this.divisions = response.body;});
      this.$http.get("get-categories").then(function (response) {this.categories = response.body;});
      this.$http.get("get-all-classes").then(function (response) {this.classes = response.body;});
      this.$http.get("registration-status").then(function (response) {
        if (response.body===true){
          if (sessionStorage.getItem('participant')){return this.$router.push({name:'ParticipantProfile'});}
        } else {return this.$router.push({name:'PageNotFound'});}
      });
      },
    updated(){
      this.rules = this.$store.getters.getRegistrationRules.content;
      this.loaded = true;
      if (this.showRulesStatus===null){
        this.showRules = true;
      }
    },
    mounted(){},
    computed:{
      registrationRules(){ return this.$store.getters.getRegistrationRules.content;}
    },
    components: {'image-upload':ImageUpload,Preloader},
    methods:{
      getDistrict(){
        this.$http.get("get-districts/"+this.studentInfo.divisionId).then(function (response) {
          this.districts = response.body;
          this.studentInfo.districtId = null;
        })
      },

      getClasses(){
        this.$http.get("get-classes/"+this.studentInfo.categoryId).then(function (response) {
          this.classes = response.body;
          this.studentInfo.classId = null;
        })
      },

      formSubmit(){
        if (this.formCheck()){
          this.loaded = false
          this.$http.post('participant-registration', this.studentInfo).then(response => {
            console.log(response.body);
            if (response.body.status=='success'){
              this.loaded = true
              this.formReset();
              // sessionStorage.removeItem('avatar');
              localStorage.setItem('participant',JSON.stringify(response.body.participant));
              this.$router.push({name:'ParticipantProfile'});
            }else {
              // alert(response.body.message);
              this.loaded = true
              document.querySelector('#errorMessage').innerHTML = response.body.message;
            }
          });
        }
      },

      formCheck(){
        for (let index in this.studentInfo){
          if (this.studentInfo[index] === null && index !== 'postCode'){
            alert('You have to fill all field correctly');
            return false;
          }
        }

        if (localStorage.getItem('avatar')){
          this.studentInfo.ppUrl = localStorage.getItem('avatar');
        }else {
          alert('Upload Photo');
          return false;
        }
        return true;
      },

      formReset(){
        for (let index in this.studentInfo){
          this.studentInfo[index] = null;
        }
      },

      hideRules(){
        this.showRules = false;
        this.showRulesStatus = false;
      }
    }
  }
</script>

<style scoped>
  #registration{
    background-color: #dddddd;
  }
  .small-logo{
    width: 50px;
  }
  input, select, textarea {
    border-top: none;
    border-right: none;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
    overflow: scroll;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
</style>
